@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Homemade+Apple&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800;6..12,900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Nunito Sans", sans-serif;
}

@font-face {
  font-family: "Product-Sans-Medium";
  src: url(assets/ProductSans-Medium.ttf);
}
@font-face {
  font-family: "HaloHandletter";
  src: url(assets/HaloHandletter.otf);
}

@font-face {
  font-family: "Product-Sans-Regular";
  src: url(assets/ProductSans-Regular.ttf);
}

/* scrollbar */
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background-color: rgba(128, 128, 128, 0.084);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 0px 0px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.227);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 128, 128, 0.537);
  transition: all 0.2s ease-in-out;
}

/* tooltip override*/
.tool {
  background-color: #d5d5d5 !important;
  margin-left: 10px !important;
  border-radius: 20px !important;
}

/* main */

#main {
  font-family: "Montserrat", sans-serif;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.main-text {
  font-size: 400px;
  font-weight: 500;
  letter-spacing: -15px;
  color: #f2f4f4c7;
}

.main-text h1:nth-child(1) {
  height: auto;
  width: auto;
  top: -150px;
  position: absolute;
  right: 0px;
}

.main-text h1:nth-child(2) {
  height: auto;
  width: auto;
  position: absolute;
  top: 120px;
  right: 10px;
}

/* nav bar */

#menu-nav,
.nav-bar {
  display: none;
}

.logo {
  width: 65px;
}

.navBar {
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0px;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 40px 80px 0px 80px;
}

.navBar nav ul {
  display: flex;
}

.navBar nav ul li {
  padding: 10px 25px;
  text-align: center;
  font-size: 18px;
  margin-right: 30px;
  cursor: pointer;
}

.nav-item:hover {
  background: #f0f0f0;
  border-radius: 50px;
  color: rgba(128, 128, 128, 0.674);
  box-shadow: 1px 1px 1px gray;
}

#contactSection {
  margin-right: 0px;
}

.loader {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "HaloHandletter", "Nunito Sans", sans-serif;
  font-size: 190px;
}

.text-animation {
  background: linear-gradient(
    to right,
    #c164e64f 20%,
    #9198e55a 40%,
    #9198e5 60%,
    #c164e6 80%
  );
  color: white;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: blink 1s linear infinite;
  font-family: "HaloHandletter", "Nunito Sans", sans-serif;
}

@keyframes blink {
  0% {
    opacity: 1;
    background-position: 0% 0%;
  }
  100% {
    opacity: 1;
    background-position: 100% 100%;
  }
}

/* home */

#home {
  width: 100%;
  height: auto;
  padding-bottom: 40px;
}

.home-container {
  display: flex;
  padding: 60px 60px;
  color: black;
  align-items: center;
}

.verticalBar {
  height: auto;
  width: 110px;
  background-color: rgba(128, 128, 128, 0.084);
  box-shadow: rgba(156, 156, 182, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 0px 0px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  border-radius: 50px;
  padding: 40px 0px;
  margin-left: 10px;
  margin-top: 30px;
  color: rgba(128, 128, 128, 0.419);
}

.verticalBar a {
  display: flex;
  justify-content: center;
}

.verticalBar a .svg-inline--fa {
  padding: 25px 20px;
  font-size: 20px;
  outline: none;
}

.verticalBar a:hover {
  background: linear-gradient(#c164e626, #9198e529);
  border-radius: 10%;
}

.home-content {
  padding-left: 70px;
  position: relative;
}

.intro {
  display: flex;
  font-size: 26px;
  align-items: center;
  letter-spacing: 0.1em;
}
.greet {
  font-weight: 800;
}

.intro img {
  width: 50px;
  margin: 0px 15px;
}

.name {
  background: linear-gradient(
    to right,
    #c164e64f 20%,
    #9198e55a 40%,
    #9198e5 60%,
    #c164e6 80%
  );
  font-weight: 800;
  background-size: 200% auto;
  color: white;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: gradientAnimate 2s linear infinite;
  font-size: 50px;
  margin-top: 10px;
  margin-bottom: 20px;
}

@keyframes gradientAnimate {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}

.home-position,
.position-cont {
  display: flex;
  align-items: center;
  font-size: 30px;
  letter-spacing: 0.1em;
  font-weight: 800;
}

.position-cont img {
  width: 50px;
  margin-left: 15px;
}

.home-content p {
  font-weight: 600;
  margin: 30px 0px;
  font-size: 18px;
  letter-spacing: 1px;
}

.see-more {
  background: linear-gradient(#c164e626, #9198e529);
  color: black;
  border: 1px solid black;
  width: 250px;
  justify-content: center;
  box-shadow: 4px 4px 1px rgba(0, 0, 0, 0.81);
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 50px;
}

.see-more p {
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.1em;
}

.see-more:hover,
.resume:hover {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.81);
  transition: all 0.2s ease-in-out;
}

.home-img {
  position: relative;
}

.home-img img {
  width: 1000px;
}

.social-connections,
.home-img-responsive,
.about-img-responsive {
  display: none;
}

/* about */

#about {
  background: url("./assets/bg-about.png");
  height: 98vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px 90px 0px 60px;
}

.about-container {
  display: flex;
  align-items: center;
  padding-top: 220px;
}

.about-img {
  width: 60%;
}

.about-content {
  width: 60%;
}

.about-img img {
  width: 800px;
}

.about-content h1 {
  background: linear-gradient(
    to right,
    #c164e64f 20%,
    #9198e55a 40%,
    #9198e5 60%,
    #c164e6 80%
  );
  background-size: 200% auto;
  color: white;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientAnimate 2s linear infinite;
  font-size: 80px;
  margin-bottom: 40px;
  margin-top: 20px;
  font-weight: 800;
}

.about-content p {
  letter-spacing: 1px;
  width: 90%;
  font-size: 18px;
  line-height: 40px;
  font-weight: 700;
}

.about-container-resp {
  display: none;
}

.about-container-resp h1 {
  font-weight: 800;
}

.switch {
  background: #55515a;
  color: rgba(128, 128, 128, 0.674);
  border-radius: 50px;
  height: 43px;
  width: 100px;
  margin-left: 20px;
  box-shadow: inset 1px -2px 1px gray, -4px 1px 1px gray;
  cursor: pointer;
  display: none;
}

.switch-layer1 {
  background: #1c1f29;
  color: rgba(128, 128, 128, 0.674);
  border-radius: 30px;
  height: 43px;
  width: 60px;
  margin-left: -2px;
  cursor: pointer;
}

.switch-layer2 {
  background: #2e323c;
  color: rgba(128, 128, 128, 0.674);
  border-radius: 20px;
  height: 43px;
  width: 60px;
  margin-left: 20px;
  cursor: pointer;
}

.switch-layer3 {
  background: #40434e;
  color: rgba(128, 128, 128, 0.674);
  border-radius: 20px;
  height: 43px;
  width: 60px;
  margin-left: 15px;
  cursor: pointer;
}

.switch-layer4 {
  background: #55515a;
  color: rgba(128, 128, 128, 0.674);
  border-radius: 25px;
  height: 43px;
  width: 60px;
  margin-left: 13px;
  cursor: pointer;
}

.moon {
  background-color: #c2c8d0;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  position: relative;
  left: 15px;
}

.spot {
  background-color: #999caf;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  position: absolute;
}

.spot:nth-child(1) {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  position: absolute;
  left: 6px;
  bottom: 12px;
}

.spot:nth-child(2) {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  position: absolute;
  left: 13px;
  bottom: 22px;
}

.spot:nth-child(3) {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  position: absolute;
  bottom: 14px;
  left: 16px;
}

/* light mode switch */
.switch-sun {
  background: #3372a7;
  color: rgba(128, 128, 128, 0.674);
  border-radius: 50px;
  height: 43px;
  width: 100px;
  margin-left: 20px;
  box-shadow: inset 1px -2px 1px gray, -4px 1px 1px gray;
  cursor: pointer;
}

.switchsun-layer4 {
  background: #3372a7;
  border-radius: 50px;
  height: 43px;
  width: 100px;
}

.switchsun-layer1 {
  background: #4789bb;
  color: rgba(128, 128, 128, 0.674);
  border-radius: 30px;
  height: 43px;
  width: 60px;
  margin-left: 22px;
  cursor: pointer;
}

.switchsun-layer2 {
  background: #5a92c0;
  color: rgba(128, 128, 128, 0.674);
  border-radius: 30px;
  height: 43px;
  width: 60px;
  cursor: pointer;
  position: relative;
  right: 15px;
}

.switchsun-layer3 {
  background: #669fc7;
  color: rgba(128, 128, 128, 0.674);
  border-radius: 30px;
  height: 43px;
  width: 50px;
  cursor: pointer;
  position: relative;
  right: 8px;
}

.sun {
  background-color: #f0c430;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  position: relative;
  left: 3px;
  top: 4px;
}

/* skills */

#skills {
  height: 100vh;
  width: 100%;
  background: white;
}

#skills h1 {
  background: linear-gradient(
    to right,
    #c164e64f 20%,
    #9198e55a 40%,
    #9198e5 60%,
    #c164e6 80%
  );
  background-size: 200% auto;
  color: white;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  animation: gradientAnimate 2s linear infinite;
  font-size: 80px;
  padding: 160px 90px 30px 90px;
}

.skills-content {
  background: url(assets/skills-bg-img.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: auto;
  position: absolute;
}

.skills-content p {
  position: relative;
  top: 40px;
  width: 50%;
  padding-left: 90px;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 1px;
  font-weight: 700;
}

.skill-icons {
  cursor: pointer;
  display: grid;
  grid-template-columns: 80px 80px 80px 80px 80px 80px;
  column-gap: 50px;
  row-gap: 20px;
  font-size: 12px;
  align-items: center;
  padding-left: 90px;
  padding-top: 80px;
  padding-bottom: 130px;
}

.icons img {
  margin-right: 40px;
}

#CI-CD,
#linux {
  padding-top: 5px;
}

#go {
  height: 80px;
}

#ReactJS,
#git {
  padding-top: 2px;
}

#docker:hover,
#aws:hover,
#linux:hover,
#ReactJS:hover,
#git:hover,
#CI-CD:hover,
#typescript:hover,
#redis:hover,
#GitHub:hover,
#go:hover,
#NodeJS:hover,
#MongoDB:hover {
  opacity: 0.8;
  animation: tilt-shaking 0.22s infinite;
}

.skills-container {
  display: flex;
  align-items: center;
}

.skills-img {
  position: relative;
  left: 1000px;
}

.skills-img img {
  width: 800px;
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* work exp */

#work {
  height: 100vh;
  width: 100%;
  padding: 30px 90px 0px 90px;
  background: url("assets/work-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.work-title {
  background: linear-gradient(
    to right,
    #c164e64f 20%,
    #9198e55a 40%,
    #9198e5 60%,
    #c164e6 80%
  );
  background-size: 200% auto;
  color: white;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: gradientAnimate 2s linear infinite;
  font-size: 80px;
  padding: 60px 90px 100px 90px;
  text-align: center;
  font-weight: 800;
}

.window-container {
  width: 700px;
  height: 470px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 0px 0px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  background-color: #fefeff;
  border-radius: 15px;
}

.window-bar {
  height: 40px;
  width: 700px;
  background-color: #dee1e6;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  padding-top: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 0px 0px -30px,
    rgba(10, 37, 64, 0.35) 0px 0px 3px 0px inset;
  border-bottom: 1px solid white;
}

.red {
  height: 10px;
  width: 10px;
  background-color: #fe6f66;
  border: 1px solid red;
  border-radius: 50%;
  margin-left: 10px;
}

.yellow {
  height: 10px;
  width: 10px;
  background-color: #fec343;
  border: 1px solid orange;
  border-radius: 50%;
}

.green {
  height: 10px;
  width: 10px;
  background-color: #3bcd50;
  border: 1px solid green;
  border-radius: 50%;
}

.color-container {
  padding: 8px 1px 8px 8px;
}

.l-arrow {
  color: #53565c;
  font-size: 14px;
  padding: 5px 10px 0px 15px;
}

.r-arrow {
  color: #b7b8ba;
  font-size: 14px;
  padding: 5px 10px 0px 3px;
}

.arrow-rotate {
  font-size: 14px;
  color: #53565c;
  padding: 5px 10px 0px 3px;
}

.url-bar {
  height: 25px;
  width: 550px;
  background-color: #f2f3f5;
  border-radius: 30px;
  margin-left: 6px;
  margin-top: 4px;
}

.url-bar p {
  margin-left: 0px;
  font-family: "Product-Sans-Regular", "Nunito Sans", sans-serif;
  font-size: 12px;
}

.lock {
  font-size: 10px;
  color: #53565c;
  padding: 0px 10px;
}

.url-avatar img {
  width: 25px;
  margin-left: 10px;
}

.position-work {
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 500;
}

.chevron {
  font-size: 10px;
}

.window-screen p {
  font-family: "Product-Sans-Medium", "Nunito Sans", sans-serif;
  letter-spacing: 1px;
}

.scroll-projects {
  color: black;
  font-weight: bolder;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 400;
}

.company {
  font-weight: 800;
  font-size: 22px;
}

/* projects */

#projects {
  height: auto;
  width: 100%;
  padding: 60px 90px;
  background-color: white;
}

.project-container {
  display: flex;
  justify-content: space-between;
  height: 60vh;
  background-color: #f4f5f4;
}

.project-info {
  width: 45%;
  margin-top: 60px;
  padding: 0px 50px;
}

.project-info h1 {
  font-size: 30px;
  letter-spacing: 5px;
  padding-bottom: 10px;
  font-weight: 800;
}

.project-info p {
  font-size: 16px;
  line-height: 35px;
  letter-spacing: 1px;
  font-weight: 700;
}

.video-container img {
  width: 100%;
  height: 60vh;
}

.video-container-resp {
  display: none;
}

/* contact */

#contact {
  height: auto;
  width: 100%;
}

.contact-title {
  font-size: 80px;
  padding-left: 90px;
  padding-top: 240px;
  padding-bottom: 70px;
  background: linear-gradient(
    to right,
    #c164e64f 20%,
    #9198e55a 40%,
    #9198e5 60%,
    #c164e6 80%
  );
  background-size: 200% auto;
  color: white;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientAnimate 2s linear infinite;
  font-weight: 800;
}

.contact-container {
  display: flex;
  justify-content: space-between;
}

.content-contact {
  background: url("assets/contact-background.png");
  background-repeat: no-repeat;
  background-size: contain;
  padding: 30px 90px 0px 90px;
  width: auto;
}

.contact-container p {
  letter-spacing: 2px;
  opacity: 0.7;
  font-weight: 700;
}

.contact-content {
  font-size: 20px;
}

.contact-mail,
.contact-phone {
  font-weight: 500;
  font-size: 18px;
}

.contact-btn {
  background-color: white;
  box-shadow: 0px 1px 2px black;
  border-radius: 30px;
  padding: 15px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
}

.contact-btn:hover {
  box-shadow: 0px 1px 1px black;
  transition: box-shadow 0.2s ease-in-out;
}

.contactBtn {
  color: gray;
  letter-spacing: 1px;
}

.contact-icons {
  font-family: "Nunito Sans", sans-serif;
}

.socials-contact a {
  margin-top: 10px;
  font-size: 20px;
  background-color: rgb(234, 234, 234);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 0px 0px -30px,
    rgba(10, 37, 64, 0.35) 0px 0px 3px 0px inset;
  color: rgba(0, 0, 0, 0.399);
  border-radius: 50px;
}

.linkedIn-socials {
  padding: 15px 21px;
  margin-right: 30px;
}

.mail-socials {
  padding: 15px 20px;
  margin-right: 30px;
}

.github-socials {
  padding: 15px 20px;
  margin-right: 30px;
}

.stackOverflow-socials {
  padding: 15px 23px;
}

.socials-contact a:hover {
  box-shadow: none;
}
.reach p {
  font-size: 18px;
}

.reach img {
  width: 20px;
  height: 20px;
}

.contact-img {
  padding-right: 90px;
}

.contact-img img {
  width: 500px;
}

/* media queries */

@media (max-width: 1700px) {
  .main-text {
    font-size: 300px;
  }
  .main-text h1:nth-child(1) {
    height: auto;
    width: auto;
    top: -110px;
    position: absolute;
    right: 0px;
  }
  .main-text h1:nth-child(2) {
    height: auto;
    width: auto;
    position: absolute;
    top: 80px;
    right: 10px;
  }
  .navBar {
    font-size: 20px;
    letter-spacing: 0px;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 40px 80px 0px 80px;
  }
  .about-content h1 {
    font-size: 80px;
    margin-bottom: 40px;
    margin-top: -90px;
  }
  .about-content p {
    font-size: 16px;
    line-height: 30px;
  }
  #about {
    padding: 0px 60px 0px 60px;
  }
  .skills-img {
    position: relative;
    left: 900px;
    bottom: 0px;
  }
  .skills-img img {
    width: 600px;
  }
  .skills-content p {
    position: relative;
    top: 40px;
    width: 60%;
    padding-left: 90px;
    font-size: 16px;
    line-height: 35px;
  }
  #skills h1 {
    font-size: 80px;
    padding: 70px 90px 60px 90px;
  }
  .skill-icons {
    cursor: pointer;
    display: grid;
    grid-template-columns: 70px 70px 70px 70px 70px 70px;
    column-gap: 50px;
    row-gap: 20px;
    font-size: 12px;
    align-items: center;
    padding-left: 90px;
    padding-top: 60px;
    padding-bottom: 130px;
  }
  .window-container {
    width: 600px;
    height: 440px;
  }
  .window-bar {
    height: 40px;
    width: 600px;
  }
  .url-bar {
    height: 25px;
    width: 450px;
  }
  .company {
    font-size: 20px;
  }
  .position-work {
    font-size: 16px;
  }
  .bullet-points {
    font-size: 15px;
  }
  .work-title {
    padding: 0px 90px 40px 90px;
  }
  .project-container {
    display: flex;
    justify-content: space-between;
    height: 60vh;
    background-color: #f4f5f4;
  }
  .project-info {
    width: 45%;
    margin-top: 30px;
    padding: 0px 50px;
  }
  .project-info h1 {
    font-size: 25px;
    letter-spacing: 5px;
  }
  .project-info p {
    font-size: 14px;
    line-height: 27px;
    letter-spacing: 1px;
  }
  .contact-content {
    font-size: 18px;
  }
  .contact-mail,
  .contact-phone {
    font-size: 15px;
  }
  .contact-img img {
    width: 400px;
    padding-right: 0px;
    padding-top: 60px;
  }
  .contact-content {
    display: block;
  }
  .content-contact {
    width: 80%;
    padding-bottom: 60px;
  }
  .contact-btn {
    background-color: white;
    box-shadow: 0px 1px 2px black;
    padding: 15px;
    cursor: pointer;
    font-size: 14px;
    width: 160px;
    margin-left: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .contact-img {
    padding-right: 0px;
  }
}

@media (max-width: 1600px) {
  .home-container {
    padding-right: 0;
    padding-top: 40px;
  }
  .intro {
    font-size: 20px;
  }
  .intro img {
    width: 40px;
  }
  .name {
    font-size: 45px;
  }
  .home-img img {
    width: 900px;
  }
  .navBar nav ul li {
    margin-right: 20px;
    font-size: 16px;
  }
  .position {
    font-size: 20px;
  }
  .home-content p {
    font-size: 15px;
  }
  .verticalBar a .svg-inline--fa {
    padding: 20px;
    font-size: 16px;
  }
  .logo {
    width: 50px;
  }
  .work-title {
    padding: 0px 90px 60px 90px;
  }
}

@media (max-width: 1510px) {
  .name {
    font-size: 40px;
  }
  .home-container {
    padding-top: 120px;
  }
  .about-content h1 {
    margin-bottom: 30px;
    margin-top: -40px;
  }
  .about-img img {
    width: 900px;
  }
  .skills-img {
    position: relative;
    left: 900px;
  }
  .skills-img img {
    width: 500px;
  }
  #skills h1 {
    font-size: 80px;
    padding: 150px 90px 100px 90px;
  }
  #skills {
    height: 100vh;
  }
  .skills-content p {
    padding-left: 80px;
  }
  #projects {
    height: auto;
    width: 100%;

    padding: 60px 90px;
    background-color: white;
  }
  .project-container {
    display: flex;
    justify-content: space-between;
    height: 50vh;
    background-color: #f4f5f4;
  }
  .project-info {
    width: 60%;
    margin-top: 30px;
    padding: 0px 50px;
  }
  .project-info h1 {
    font-size: 25px;
    letter-spacing: 5px;
  }
  .project-info p {
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 1px;
  }
  .video-container img {
    width: 100%;
    height: 50vh;
  }
  .content-contact {
    width: 80%;
    padding-bottom: 50px;
  }
}

@media (max-width: 1430px) {
  .window-container {
    width: 550px;
    height: 470px;
  }
  .window-bar {
    height: 40px;
    width: 550px;
  }
  .url-bar {
    height: 25px;
    width: 400px;
  }
  .company {
    font-size: 18px;
  }
  .position-work {
    font-size: 15px;
  }
  .bullet-points {
    font-size: 14px;
  }
  .work-title {
    padding: 0px 90px 60px 90px;
  }
  #work {
    height: auto;
  }
  .scroll-text {
    padding-bottom: 50px;
  }
  .content-contact {
    width: 100%;
    padding-bottom: 50px;
    background-size: cover;
  }
  .contact-img {
    display: none;
  }
}

@media (max-width: 1400px) {
  .about-img {
    width: 80%;
  }
  .about-img img {
    width: 1200px;
  }
  .about-content h1 {
    font-size: 80px;
    margin-bottom: 30px;
    margin-top: -70px;
  }
  .about-content p {
    font-size: 16px;
    line-height: 30px;
  }
  .skills-img {
    position: relative;
    left: 800px;
    bottom: 50px;
  }
  #skills h1 {
    font-size: 80px;
    padding: 170px 90px 100px 90px;
  }
  .skill-icons {
    grid-template-columns: 50px 50px 50px 50px 50px 50px;
  }
  #go {
    height: 50px;
  }
  .project-container {
    display: flex;
    justify-content: space-between;
    height: 50vh;
    background-color: #f4f5f4;
  }
  .project-info {
    width: 45%;
    margin-top: 20px;
    padding: 0px 40px;
  }
  .project-info h1 {
    font-size: 20px;
    letter-spacing: 5px;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .project-info p {
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 1px;
  }
  .video-container img {
    width: 100%;
    height: 50vh;
  }
}

@media (max-width: 1300px) {
  .position {
    font-size: 18px;
  }
  .position-cont img {
    margin-left: 10px;
  }
  .home-content {
    padding-left: 40px;
  }
  .verticalBar a .svg-inline--fa {
    padding: 15px;
    font-size: 15px;
  }
  .navBar {
    padding: 30px 60px;
  }
  .home-container {
    padding-left: 60px;
  }
  .navBar nav ul li {
    font-size: 15px;
  }
  .skills-img {
    position: relative;
    left: 800px;
    bottom: 50px;
  }
  .skills-img {
    position: relative;
    left: 750px;
    bottom: 50px;
  }
  .window-container {
    width: 500px;
    height: 470px;
  }
  .window-bar {
    width: 500px;
  }
  .url-bar {
    height: 25px;
    width: 350px;
  }
  .work-title {
    padding: 0px 90px 60px 90px;
  }
  #work {
    height: auto;
  }
  .project-info {
    width: 50%;
    margin-top: 20px;
    padding: 0px 40px;
  }
}

@media (max-width: 1250px) {
  .verticalBar,
  .home-img,
  .about-img {
    display: none;
  }
  .home-content {
    padding-left: 0;
    text-align: center;
  }
  .home-container,
  .intro,
  .home-position {
    justify-content: center;
  }
  .see-more {
    margin: auto;
  }
  .home-container {
    padding: 50px 0px 0px 0px;
  }
  .name {
    font-size: 50px;
  }
  .social-connections {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 60px 30px 60px;
  }
  .socials {
    width: auto;
    height: 80px;
    background-color: rgba(128, 128, 128, 0.084);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 0px 0px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .socials a {
    padding: 28px 30px;
  }
  .socials a .svg-inline--fa {
    color: rgba(128, 128, 128, 0.419);
    font-size: 18px;
  }
  .socials a:hover {
    background: linear-gradient(#c164e626, #9198e529);
  }
  .Github {
    margin-left: 30px;
  }
  .StackOverflow {
    margin-right: 40px;
  }
  .intro-text {
    width: 70%;
    margin: auto;
  }
  .home-img-responsive,
  .about-img-responsive {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .home-img-responsive img,
  .about-img-responsive img,
  .about-img-responsive img {
    width: 700px;
  }
  .about-container {
    display: none;
  }
  #about {
    background: none;
    padding: 0;
    height: auto;
  }
  .about-container-resp {
    display: block;
    text-align: center;
  }
  .about-container-resp h1 {
    background: linear-gradient(
      to right,
      #c164e64f 20%,
      #9198e55a 40%,
      #9198e5 60%,
      #c164e6 80%
    );
    background-size: 200% auto;
    color: white;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    animation: gradientAnimate 2s linear infinite;
    font-size: 80px;
    padding: 50px 0px 30px 0px;
  }
  .aboutcontent {
    background: url(assets/bg-about-resp.png);
    height: auto;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    font-weight: 700;
  }
  .aboutcontent p {
    width: 80%;
    margin: auto;
    padding: 30px 0px 60px 0px;
    letter-spacing: 1px;
    line-height: 30px;
  }
  .skills-img {
    display: none;
  }
  .skills-content {
    position: static;
    background-size: cover;
  }
  .skills-content p {
    line-height: 30px;
    width: 90%;
    margin: auto;
    padding-left: 60px;
    padding-right: 60px;
    text-align: center;
  }
  #skills h1 {
    padding-bottom: 30px;
    text-align: center;
    padding-top: 60px;
  }
  .skill-icons {
    padding-top: 70px;
    padding-left: 0px;
    justify-content: center;
    grid-template-columns: 90px 90px 90px 90px 90px 90px;
    column-gap: 50px;
    row-gap: 20px;
    font-size: 12px;
    padding-bottom: 70px;
  }
  #go {
    height: 90px;
  }
  #skills {
    height: auto;
  }
  #work {
    background-image: none;
  }
  .work-container {
    display: block;
  }
  .window-container {
    margin: auto;
    margin-bottom: 60px;
    width: 700px;
    height: 470px;
  }
  .window-bar {
    height: 40px;
    width: 700px;
  }
  .url-bar {
    height: 25px;
    width: 550px;
  }
  .company {
    font-size: 20px;
  }
  .position-work {
    font-size: 17px;
  }
  .bullet-points {
    font-size: 16px;
  }
  .work-title {
    padding: 0px 90px 60px 90px;
  }
  #work {
    height: auto;
  }
  .scroll-text {
    padding-bottom: 50px;
    margin-top: -40px;
  }
  .scroll-projects {
    font-size: 20px;
  }
  .project-container {
    display: flex;
    justify-content: space-between;
    height: auto;
    background-color: #f4f5f4;
  }
  .project-info {
    width: 100%;
    margin-top: 20px;
    padding: 0px 40px;
  }
  .project-info h1 {
    font-size: 40px;
    letter-spacing: 5px;
    margin-bottom: 30px;
    margin-top: 20px;
    text-align: center;
  }
  .project-info p {
    font-size: 15px;
    line-height: 35px;
    letter-spacing: 1px;
    padding: 0px 40px 60px 40px;
    text-align: center;
  }
  .video-container {
    display: none;
  }
  .video-container-resp {
    display: block;
  }
  .video-container-resp img {
    width: auto;
    height: auto;
  }
}

@media (max-width: 1150px) {
  .contact-content,
  .reach p {
    font-size: 16px;
  }
}

@media (max-width: 1000px) {
  .work-title {
    padding: 0px;
    padding-bottom: 70px;
  }
}

@media (max-width: 900px) {
  .navBar {
    display: none;
  }
  #menu-nav {
    display: block;
    position: relative;
  }
  .burger div {
    width: 25px;
    height: 3px;
    background-color: rgba(0, 0, 0, 0.784);
    margin: 5px;
    border-radius: 2px;
  }
  .nav-bar-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 30px 60px;
  }
  .nav-bar {
    flex-direction: column;
    width: 100%;
    text-align: center;
    height: 100svh;
    background-color: #f8f9f9;
    position: relative;
    color: black;
  }
  .nav-bar li {
    font-size: 20px;
    font-weight: 600;
    list-style: none;
  }
  .nav-bar a:nth-child(1) {
    padding-top: 90px;
  }
  .nav-bar a {
    padding: 30px;
  }
  .nav-bar li:hover {
    color: gray;
  }
  .nav-active {
    display: flex;
  }
  .toggle .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  .toggle .line2 {
    opacity: 0;
  }
  .toggle .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
  .home-img-responsive img,
  .about-img-responsive img,
  .about-img-responsive img {
    width: 600px;
  }
  .aboutcontent p {
    font-size: 15px;
  }
  .skills-content p {
    font-size: 15px;
  }
  .skill-icons {
    grid-template-columns: 70px 70px 70px 70px 70px 70px;
  }
  #go {
    height: 70px;
  }
  .loader {
    font-size: 120px;
  }
  .work-container {
    display: block;
  }
  .window-container {
    margin: auto;
    margin-bottom: 60px;
    width: 600px;
    height: 450px;
  }
  .window-bar {
    height: 40px;
    width: 600px;
  }
  .url-bar {
    height: 25px;
    width: 450px;
  }
  .company {
    font-size: 19px;
  }
  .position-work {
    font-size: 16px;
  }
  .bullet-points {
    font-size: 15px;
  }
  #work {
    height: auto;
  }
  .scroll-text {
    padding-bottom: 50px;
    margin-top: -40px;
  }
  .scroll-projects {
    font-size: 20px;
  }
}

@media (max-width: 800px) {
  .main-text {
    font-size: 250px;
  }
  .main-text h1:nth-child(1) {
    top: -90px;
  }
  .home-img-responsive img,
  .about-img-responsive img,
  .about-img-responsive img {
    width: 500px;
  }
  .name {
    font-size: 40px;
  }
  .intro h1 {
    font-size: 16px;
  }
  .see-more p {
    font-weight: 600;
    font-size: 14px;
  }
  .see-more-div {
    margin-top: 50px;
    margin-bottom: 10px;
  }
  .socials {
    width: auto;
    height: 70px;
  }
  .socials a {
    padding: 22px 25px;
  }
  .socials a .svg-inline--fa {
    color: rgba(128, 128, 128, 0.419);
    font-size: 16px;
  }
  .socials a:hover {
    background: linear-gradient(#c164e626, #9198e529);
  }
  .Github {
    margin-left: 30px;
  }
  .StackOverflow {
    margin-right: 40px;
  }
  .about-container-resp h1,
  #skills h1,
  .work-title {
    font-size: 60px;
  }
  .skill-icons {
    grid-template-columns: 55px 55px 55px 55px 55px 55px;
  }
  #go {
    height: 55px;
  }
  .window-container {
    margin: auto;
    margin-bottom: 60px;
    width: 500px;
    height: 440px;
  }
  .window-bar {
    height: 40px;
    width: 500px;
  }
  .url-bar {
    height: 25px;
    width: 350px;
  }
  .company {
    font-size: 18px;
  }
  .position-work {
    font-size: 16px;
  }
  .bullet-points {
    font-size: 14px;
  }
  .scroll-text {
    padding-bottom: 20px;
    margin-top: -40px;
  }
  .scroll-projects {
    font-size: 17px;
  }
  .project-info h1 {
    font-size: 35px;
    letter-spacing: 5px;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
  }
  .project-info p {
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 1px;
    padding: 0px 20px 60px 20px;
    text-align: center;
  }
  .contact-title {
    padding-top: 60px;
    font-size: 60px;
  }
}

@media (max-width: 680px) {
  .home-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .skill-icons {
    grid-template-columns: 50px 50px 50px 50px 50px 50px;
    column-gap: 30px;
  }
  #go {
    height: 50px;
  }
  #work,
  #projects,
  .content-contact {
    padding-left: 60px;
    padding-right: 60px;
  }
  .contact-title {
    padding: 60px 0px 50px 0px;
    text-align: center;
  }
}

@media (max-width: 630px) {
  .main-text {
    font-size: 200px;
  }
  .main-text h1:nth-child(1) {
    top: -70px;
  }
  .name {
    font-size: 35px;
  }
  .intro h1 {
    font-size: 15px;
  }
  .see-more p {
    font-size: 13px;
  }
  .see-more-div {
    margin-top: 40px;
    margin-bottom: 10px;
  }
  .skill-icons {
    grid-template-columns: 40px 40px 40px 40px 40px 40px;
    column-gap: 30px;
  }
  #go {
    height: 40px;
  }
  .window-container {
    margin: auto;
    margin-bottom: 60px;
    width: 450px;
    height: 440px;
  }
  .window-bar {
    width: 450px;
  }
  .url-bar {
    width: 300px;
  }
  .company {
    font-size: 18px;
  }
  .position-work {
    font-size: 16px;
  }
  .bullet-points {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .socials a {
    padding: 22px 20px;
  }
  .intro img,
  .position-cont img {
    width: 40px;
  }
}

@media (max-width: 570px) {
  .window-container {
    margin: auto;
    margin-bottom: 60px;
    width: 400px;
    height: 460px;
  }
  .window-bar {
    width: 400px;
  }
  .url-bar {
    width: 250px;
  }
  .company {
    font-size: 16px;
  }
  .position-work {
    font-size: 14px;
  }
  .bullet-points {
    font-size: 13px;
  }
  #work {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 530px) {
  .home-img-responsive img,
  .about-img-responsive img,
  .about-img-responsive img {
    width: 450px;
  }
  .socials a {
    padding: 22px 15px;
  }
  .socials a .svg-inline--fa {
    color: rgba(128, 128, 128, 0.419);
    font-size: 14px;
  }
  .position {
    font-size: 16px;
  }
  .intro-text p,
  .intro h1 {
    font-size: 14px;
  }
  .intro-text {
    width: 85%;
  }
  .see-more {
    width: 200px;
    height: 40px;
  }
  .see-more p {
    font-size: 12px;
  }
  .aboutcontent p,
  .skills-content p {
    font-size: 14px;
  }
  .loader {
    font-size: 90px;
  }
  .project-info h1 {
    font-size: 30px;
    letter-spacing: 5px;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
  }
  .project-info p {
    font-size: 13px;
    line-height: 25px;
    letter-spacing: 1px;
    padding: 0px 0px 40px 0px;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .main-text {
    font-size: 150px;
    letter-spacing: -8px;
  }
  .main-text h1:nth-child(1) {
    top: -50px;
  }
  .main-text h1:nth-child(2) {
    top: 50px;
  }
  .logo {
    width: 50px;
  }
  .nav-bar-logo {
    padding: 30px;
  }
  .about-container-resp h1,
  #skills h1,
  .work-title {
    font-size: 50px;
  }
  .skill-icons {
    column-gap: 20px;
  }
  .skills-content p {
    width: 100%;
  }
  .work-title {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
  .scroll-projects {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .name {
    font-size: 30px;
  }
  #plus.position {
    margin-left: 4px;
    margin-right: 4px;
  }
  .aboutcontent p,
  .skills-content p {
    font-size: 13px;
    line-height: 28px;
  }
  .about-container-resp h1,
  #skills h1,
  .work-title {
    font-size: 45px;
  }
  .skill-icons {
    grid-template-columns: 30px 30px 30px 30px 30px 30px;
  }
  #go {
    height: 30px;
  }
  #projects {
    padding-left: 40px;
    padding-right: 40px;
  }
  .contact-title {
    font-size: 45px;
  }
  .content-contact {
    padding-left: 40px;
    padding-right: 40px;
  }
  .contact-content p,
  .reach p {
    font-size: 13px;
  }
  .contact-btn {
    font-size: 12px;
    width: 150px;
  }
  .contact-mail,
  .contact-phone {
    font-size: 13px;
  }
}

@media (max-width: 460px) {
  .window-container {
    margin: auto;
    margin-bottom: 60px;
    width: 360px;
    height: 500px;
  }
  .window-bar {
    width: 360px;
  }
  .url-bar {
    width: 210px;
  }
  .company {
    font-size: 15px;
  }
  .position-work {
    font-size: 14px;
  }
  .bullet-points {
    font-size: 13px;
  }
  #work,
  .content-contact {
    padding-left: 30px;
    padding-right: 30px;
  }
  .project-info p {
    font-size: 12px;
  }
}

@media (max-width: 430px) {
  .name {
    font-size: 25px;
  }
  .home-img-responsive img,
  .about-img-responsive img,
  .about-img-responsive img {
    width: 400px;
  }
  .socials a .svg-inline--fa {
    font-size: 13px;
  }
  .position {
    font-size: 14px;
    letter-spacing: 1px;
  }
  .intro-text {
    width: 90%;
  }
  .intro-text p {
    font-size: 13px;
  }
  #work {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 400px) {
  .Github {
    margin-left: 24px;
  }
  .StackOverflow {
    margin-right: 28px;
  }
  .socials {
    width: auto;
    height: 60px;
  }
  .socials a {
    padding: 18px 12px;
  }
  .socials a .svg-inline--fa {
    font-size: 11px;
  }
  .intro img,
  .position-cont img {
    width: 30px;
  }
  .window-container {
    margin: auto;
    margin-bottom: 30px;
    width: 340px;
    height: 420px;
  }
  .window-bar {
    width: 340px;
  }
  .url-bar {
    width: 190px;
  }
  .company {
    font-size: 14px;
  }
  .position-work {
    font-size: 13px;
  }
  .bullet-points {
    font-size: 12px;
  }
  #work {
    padding-left: 10px;
    padding-right: 10px;
  }
  .url-bar p {
    font-size: 11px;
  }
  .window-screen {
    padding: 20px 30px;
  }
}

@media (max-width: 380px) {
  .window-container {
    margin: auto;
    margin-bottom: 30px;
    width: 320px;
    height: 430px;
  }
  .window-bar {
    width: 320px;
  }
  .url-bar {
    width: 170px;
  }
  .company {
    font-size: 14px;
  }
  .position-work {
    font-size: 12px;
  }
  .bullet-points {
    font-size: 12px;
  }
  #work {
    padding-left: 10px;
    padding-right: 10px;
  }
  .url-bar p {
    font-size: 11px;
  }
  .window-screen {
    padding: 20px 30px;
  }
  #projects {
    padding-left: 20px;
    padding-right: 20px;
  }
  #project-cont {
    margin-top: 50px;
  }
  .socials-contact {
    justify-content: center;
  }
  .contactBtn {
    display: flex;
    justify-content: center;
  }
  .socials-contact a {
    font-size: 15px;
  }

  .linkedIn-socials {
    padding: 15px 10px;
    margin-right: 20px;
  }

  .mail-socials {
    padding: 15px 10px;
    margin-right: 20px;
  }

  .github-socials {
    padding: 15px 10px 0px 10px;
    margin-right: 20px;
  }

  .stackOverflow-socials {
    padding: 15px 12px;
  }
}

@media (max-width: 350px) {
  .main-text {
    font-size: 120px;
    letter-spacing: -8px;
  }
  .main-text h1:nth-child(1) {
    top: -40px;
  }
}
